



























import { TagModel } from '@/models/post-model'
import { Observer } from 'mobx-vue'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { SearchViewModel } from '../viewmodels/search-viewmodel'

@Observer
@Component({
  components: {
    'follow-button': () => import('@/modules/profile/activity/pages/components/following/follow-button.vue'),
  },
})
export default class Tag extends Vue {
  @Inject() vm!: SearchViewModel
  @Prop() tag!: TagModel
}
